import { Row, Col, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";

const Policy = () => (
  <div id="privacy" className="bg-white">
    <Container>
      <Row className="content-block">
        <Col>
          <ol>
            <li>
              <p>
                <strong>Our Privacy Philosophy</strong>
                <br />
                At Knockr, Inc. operating as Knockr ("Knockr", "we" or "us"), we
                sincerely value the trust that you have placed in our business.
                We have adopted this privacy policy (the "Policy") to affirm our
                commitment to maintaining the accuracy, confidentiality, and
                security of personal information.
              </p>
              <p>
                This Policy explains how we collect, use, disclose and safeguard
                the personal information that you or a third party provide.
                Providing personal information or authorizing a third party to
                disclose personal information to us signifies your consent to
                Knockr's collection, use and disclosure of personal information
                in accordance with this Policy. If you provide personal
                information to us about a third party (for example, the name,
                address, and other shipping information with respect to the
                recipient of a package) or authorize a third party to disclose
                personal information to us, you agree that you were authorized
                to do so.
              </p>
              <p>
                For the purposes of this Policy, "personal information" means
                information that can identify an individual directly or through
                other reasonably available means. "Personal information" does
                not include information that is used for the purpose of
                communicating or facilitating communication with an individual
                in relation to their employment, business, or profession.
                Notwithstanding anything to the contrary herein, the meaning of
                "personal information" shall be interpreted in a manner that
                conforms with the minimum requirements of applicable privacy
                legislation.
              </p>
            </li>
            <hr />
            <li>
              <p>
                <strong>Identifying Purposes and Obtaining Your Consent</strong>
                <br />
                We identify the purposes for collecting personal information at
                or before the time it is collected. We will not collect, use or
                disclose your personal information without your informed
                consent, unless required or permitted to do so by law.
              </p>
              <p>
                At Knockr, we collect and use personal information so we can:
                (i) provide, maintain, and improve our products and services,
                which includes using the information to fulfill orders, verify
                your identity, process payments, allocate resources, track the
                progress of any deliveries, and otherwise run and manage our
                business in the ordinary course; (ii) provide information to our
                employees, contractors, and affiliates to allow them to perform
                services on our behalf, including the provision of personal
                information to service providers engaged by Knockr as part of
                the delivery process; (iii) send you newsletters or other
                communications to which you have subscribed or may be
                interested; (iv) comply generally with privacy laws and all
                other applicable regulatory requirements; (v) understand the
                demographics, interests, usage patterns, and other
                characteristics of customers and recipients of packages and to
                track and analyze trends and patterns, including through the
                aggregation of personal information; (vi) administer surveys,
                contests, draws, and raffles; (vii) offer you tailored content,
                including advertisements and promotions; and (viii) assist you
                when you contact our customer support services, including to
                direct your questions to appropriate individuals, investigate
                and address any of your concerns, and to improve and monitor our
                customer support responses. We may also use your personal
                information for purposes that are otherwise consistent with the
                terms of this Policy or that are permitted or required by law,
                and for purposes for which you have otherwise provided consent.
              </p>
              <p>
                Should we require your information to fulfill a purpose that is
                not identified in this Policy, we will obtain your consent
                before proceeding.
              </p>
            </li>
            <hr />
            <li>
              <strong>
                Limiting the Collection, Use and Disclosure of Your Personal
                Information
              </strong>
              <ol>
                <li>
                  <p>
                    <strong>The information we collect</strong>
                    <br />
                    The type of personal information that we may collect,
                    including when you register for an account or place an
                    order, includes: (i) your name, mailing address, email
                    address, telephone number, and the contents and dollar value
                    of any packages for delivery; (ii) with respect to the
                    person to whom goods are being delivered, their name,
                    mailing address, email address, and telephone number; and
                    (iii) other information that you share in your
                    communications with us, our employees, or our contractors,
                    including on or through our website www.Knockr.com or any
                    other websites operated by Knockr (collectively, the
                    "Website") and our applications.
                  </p>
                  <p>
                    The choice to provide us with your personal information,
                    either directly or through a third party, is yours. However,
                    your decision to withhold particular information may limit
                    our ability to provide you with some of our products or
                    services.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>How your information is collected</strong>
                    <br />
                    Personal information may be collected in a number of ways,
                    including: in person, over the phone, by mail, by fax, over
                    the Internet (including from our Website, by email, or
                    through any of our applications or other software that we
                    provide), and from third parties whom you have authorized to
                    disclose personal information to us.
                  </p>
                  <p>
                    If you are the recipient of a package, we have obtained
                    personal information, including your name and shipping
                    information, from our customer.
                  </p>
                  <p>
                    We may collect usage information from users of our services,
                    including through the use of cookies, web beacons and
                    tracking pixels, SDKs, and other similar technologies.
                    Cookies are very small text files that are stored on your
                    browser or device. Usage information collected from our
                    Website and our applications may include your device type,
                    device identifier, IP address, MAC address, location,
                    browser type, operating system, duration of use, user
                    behaviour, length of time spent on the services, and
                    frequency of use. We may use this information for purposes
                    including remembering your preferences and settings,
                    determining the popularity of certain content,
                    authenticating users, determining the effectiveness of any
                    advertising campaigns, and analyzing the behaviour and
                    interests of people (including traffic and trends) accessing
                    our Website and applications. We may also allow others to
                    provide analytics services (e.g. Google Analytics) and
                    audience measurement services for us, to serve
                    advertisements on our behalf on the Internet, and to monitor
                    and report on the performance of those advertisements. These
                    third parties may perform such services through the use of
                    cookies, web beacons and tracking pixels, SDKs, and other
                    similar technologies. By modifying your browser settings,
                    you may be able to block cookies or otherwise make
                    adjustments to the use of cookies, although this may affect
                    the functioning of certain Knockr services. You may also
                    visit http://optout.networkadvertising.org/?c=1 to opt out
                    of certain other cookies.
                  </p>
                  <p>
                    If you believe that a third party has inappropriately
                    disclosed your personal information to us, please contact
                    that third party directly. If the third party does not
                    sufficiently respond to your inquiries, please let us know
                    immediately.
                  </p>
                  <p>
                    Your personal information will only be used or disclosed in
                    accordance with the purposes for which it was collected,
                    unless you have otherwise consented or except where required
                    or permitted by law.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>We may monitor and record telephone calls</strong>
                    <br />
                    For the purpose of maintaining quality service, telephone
                    calls to our customer service lines may be recorded. If your
                    call is subject to a quality assurance program, you will be
                    so advised prior to speaking with a representative.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Disclosure to third parties</strong>
                    <br />
                    We may share your personal information with our service
                    providers in order for them to provide services to or on
                    behalf of Knockr, including processing and storage. These
                    may include, for example, cloud storage providers, data
                    analytics providers, and third parties that we engage to
                    help us deliver packages. To that end, when you provide
                    personal information to Knockr, the information may be
                    stored on servers hosted or owned by a third party.
                  </p>
                  <p>
                    If you use a credit or debit card or other method of payment
                    for your purchase, information with respect to your payment
                    method is required to process your payment. We do not store
                    your payment information. Your payment information will be
                    provided directly to our third party payment provider.
                  </p>
                  <p>
                    To complete a requested delivery, we need to share
                    information about you and your order with the other parties
                    who are involved in this process. We will only share
                    personal information in this context to the extent
                    reasonably necessary. For example, your personal information
                    may need to be provided to customs officials or listed on a
                    package to identify your name and address as either the
                    sender or the intended recipient of the package.
                  </p>
                  <p>
                    If you place a delivery order, we may share information
                    about the delivery status (which may include your name,
                    address, and the location from where the package was sent)
                    with the intended recipient of the package, in order to
                    allow them to track the delivery of the package.
                  </p>
                  <p>
                    We may (i) share your personal information with our
                    affiliates; (ii) combine information internally across our
                    affiliates or our different products and services; and (iii)
                    combine personal information with demographic information,
                    publicly available records and other third party information
                    sources. We may use this information to provide you with a
                    customized experience, to promote and develop products and
                    services available through Knockr or its affiliates, or as
                    otherwise permitted by this Policy.
                  </p>
                  <p>
                    If you post a review, make a comment, or otherwise submit
                    personal information on a public forum such as social media
                    accounts or public forums on our Website or other
                    applications, your communications may be viewable by the
                    public.
                  </p>
                  <p>
                    We may also disclose personal information in situations
                    where we are legally required or permitted to do so. The
                    type of information we are legally required to disclose may
                    relate to criminal investigations or government tax
                    reporting requirements. In some instances, such as a legal
                    proceeding or court order, we may also be required to
                    disclose your personal information to authorities. Only the
                    information specifically requested is disclosed and we take
                    precautions to satisfy ourselves that the authorities that
                    are making the disclosure request have legitimate grounds to
                    do so. Your personal information may be disclosed in
                    situations where we are legally permitted to do so, such as
                    in the course of employing reasonable and legal methods to
                    enforce your rights or to investigate suspicion of unlawful
                    activities. We may release certain personal information when
                    we believe that such release is reasonably necessary to
                    protect the rights, property and safety of ourselves and
                    others. We may use and disclose your personal information to
                    third parties in connection with the proposed or actual
                    financing, insuring, sale, securitization, assignment or
                    other disposal of all or part of our business or assets
                    (including accounts) for the purposes of evaluating and/or
                    performing the proposed transaction. These purposes may
                    include, as examples, permitting such parties to determine
                    whether to proceed or continue with the transaction,
                    fulfilling any reporting or audit requirements to such
                    parties, and/or disclosing personal information as part of
                    concluding a sale or transfer of assets. Our successors and
                    assigns may collect, use and disclose your personal
                    information for substantially the same purposes as those set
                    out in this Policy.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Keeping your information accurate</strong>
                    <br />
                    We make every reasonable effort to keep your personal
                    information as accurate, complete and up-to-date as
                    necessary. If desired, you may verify the accuracy and
                    completeness of your personal information in our records.
                  </p>
                  <p>
                    Despite our efforts, errors sometimes do occur. Should you
                    identify any incorrect or out-of-date information in your
                    file, we will remedy any such errors on a timely basis. In
                    the event that inaccurate information is mistakenly sent to
                    a third party, we will communicate relevant changes to the
                    third party where appropriate.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Protecting Your Personal Information</strong>
                    <br />
                    Access to private, sensitive and confidential information,
                    including your personal information, is restricted to
                    authorized employees or contractors with legitimate business
                    reasons. For example, in the course of processing a package
                    for delivery, employees or contractors may have access to
                    your name, mailing address, and other personal information
                    as written on the package to be delivered.
                  </p>
                  <p>
                    Our employees and contractors understand the importance of
                    keeping your information private. All employees and
                    contractors are expected to maintain the confidentiality of
                    personal information at all times and failure to do so will
                    result in appropriate disciplinary measures including
                    dismissal.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Disposal and Retention</strong>
                    <br />
                    Except as otherwise permitted or required by law, your
                    personal information will be retained for so long as is
                    reasonably necessary to fulfil the purposes for which it was
                    collected.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Safeguarding your information</strong>
                    <br />
                    To ensure that your personal information is protected, we
                    utilize security safeguards that are on par with the
                    industry standard. We keep electronic customer files in a
                    secured environment with restricted access and use.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Addressing Your Inquiries and Concerns</strong>
                    <br />
                    We are happy to provide you with a copy of this Policy and
                    to discuss any of its content with you.
                    <br />
                    Upon request, we will also inform you of: the type of
                    personal information we have collected, how your personal
                    information has been used, and any third parties to whom
                    your personal information has been disclosed.
                  </p>
                  <p>
                    Please direct all questions or enquiries about this Policy
                    to:
                  </p>
                  <p>
                    Privacy Officer, Knockr
                    <br />
                    21 Durrow St
                    <br />
                    London, ON N6E 0B5
                    <br />
                    Email: info@virtual.com
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Updating this Privacy Policy</strong>
                    <br />
                    Any changes to our privacy standards and information
                    handling practices will be reflected in this Policy in a
                    timely manner. Knockr reserves the right to change, modify,
                    add, or remove portions of this Policy at any time. Please
                    check this page periodically for any modifications. To
                    determine when this Policy was last updated, please refer to
                    the modification date at the bottom of this Policy.
                  </p>
                  <p>
                    Knockr strives to evolve to meet our customer's needs and
                    expectations and that means our products and services may
                    change and how we offer our products and services may
                    change. With that in mind, we review our privacy practices
                    from time to time, and that our information handling
                    practices may change. If Knockr makes a material change to
                    this Policy, we will post a notice on our Website and
                    highlight the changes. We may also notify you by email.
                    Material changes to this Policy will be binding thirty (30)
                    days after such changes have been introduced and for which
                    notification of such material changes has been made. You may
                    determine when this Policy was last updated by referring to
                    the date found at the bottom of this Policy. If at any point
                    you do not agree with the terms of this Policy, you must not
                    use Knockr's services.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Website and Apps Governed by this Privacy Policy
                    </strong>
                    <br />
                    Our Website and any of our applications available from our
                    Website or third party platforms (including the iTunes Store
                    and Google Play) are governed by the provisions and
                    practices stated in this Policy. Our Website and such
                    applications may contain links to third party sites or
                    applications that are not governed by this Policy. Although
                    we endeavour to only link to sites or applications that
                    share our commitment to your privacy, please be aware that
                    this Policy will no longer apply once you leave our Website
                    or such applications, and that we are not responsible for
                    the privacy practices of third party sites or applications.
                    We therefore suggest that you closely examine the respective
                    privacy policies of third party sites and applications to
                    learn how they collect, use and disclose your personal
                    information.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Governing Law</strong>
                    <br />
                    This Policy and all related matters shall be interpreted and
                    construed in accordance with the laws of the Province of
                    Ontario and the applicable federal laws of Canada.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Personal Information</strong>
                    <br />
                    Outside of Canada Depending on the recipient's address and
                    the route determined by Knockr for such shipment, a package
                    may be shipped outside of Ontario and outside of Canada.
                    Personal information provided to our payment processors may
                    be stored outside of Ontario and outside of Canada. You
                    acknowledge and agree that, as a result, your personal
                    information may be processed, used, stored or accessed in
                    other jurisdictions and may be subject to the laws of those
                    jurisdictions. For example, information may be disclosed in
                    response to valid demands or requests from government
                    authorities, courts, or law enforcement in other countries.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  </div>
);

const Hero = () => (
  <Container className="info-block pt-5 pb-3">
    <Row>
      <Col>
        <h2>Privacy Policy</h2>
      </Col>
    </Row>
  </Container>
);

const Privacy = () => (
  <>
    <Hero />
    <Policy />
  </>
);

export default Privacy;
