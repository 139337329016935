import { Col, Container, Row } from "react-bootstrap";
import Logo from "./Logo";

const fullYear = new Date().getFullYear();

export const Footer = () => (
  <footer className="bg-purple content-block">
    <Container>
      <Row>
        <Col md={6}>
          <Logo />
          <br />
          Delivery experience automation
          <br />
          <br />
          <br />
        </Col>
        <Col>
          <div className="pull-right">
            <p className="header">Get started</p>
            <a href="mailto:info@virtual.com">Learn more</a>
          </div>
          <br />
          <br />
          <br />
        </Col>
      </Row>
      <hr />
      <Row className="muted">
        <Col md={6}>
          <p>
            A{" "}
            <a href="https://www.virtual.com/" rel="noreferrer" target="_blank">
              Virtual
            </a>{" "}
            company
          </p>
          <p>
            This site provides information and access to products and services
            offered by Knockr. All users of our online services are subject to
            the <a href="/privacy">Privacy Policy</a>.
          </p>
        </Col>
        <Col>
          <div className="pull-right">
            <p>
              &copy;&nbsp;Knockr&nbsp;{fullYear}
              .&nbsp;All&nbsp;Rights&nbsp;Reserved. &nbsp;&nbsp;&nbsp;
              <a href="/privacy">Privacy Policy</a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
);
