import { Row, Col, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Highlight } from '../components/Highlight';

const DeliveringPromise = () => <div className="bg-white">
<Container>
  <Row className="content-block">
    <Col sm={12} md={6}>
      <img className="info-img" src="/img/delivering-promise.png" />
    </Col>
    <Col sm={12} md={5} className="info-block large vert-center">
      <div>
        <h2>Delivering <Highlight>promise</Highlight></h2>
        <p className="copy-lg">
          For both delivery carriers and customers, Knockr represents a revolutionary shift in the delivery experience.
        </p>
      </div>
    </Col>
  </Row>
  <Row>
    <Col sm={12} md={6} xl={{span:5}} className="info-block">
      <img src="/img/efficiency.png" className="info-img sm" />
      <h2>Predictable efficiency</h2>
      <p>Currently, a delivery driver whose route has 30 undeliverable packages may spend up to 2 extra hours at the curb - every day.</p>
      <p>With Knockr, that same driver will consistently spend just minutes a day.</p>
    </Col>
    <Col sm={12} md={6} xl={{span:5, offset:1}} className="info-block">
      <img src="/img/experience.png" className="info-img sm" />
      <h2>Seamless experience</h2>
      <p>Rather than a complicated form, a Knockr sheet presents the customer with clear and easy options. A single phone camera scan will take them to your tracking or redelivery page.</p>
    </Col>
  </Row>
  <hr className="lg"/>
  <Row>
    <Col className="text-center content-block mb-5">
      <p className="callout">Contact us today to learn more about how you and your customers can get started&nbsp;with&nbsp;Knockr.</p>
      <Button href="mailto:info@virtual.com">Learn more</Button>
    </Col>
  </Row>
</Container>
</div>;

const ValuePropositions = () => <div className="bg-white">
<Container>
  <Row className="content-block">
    <Col className="info-block  vert-center" xs={{ span: 12, order: 'last' }} md={{ span: 5, order: 'first' }}>
      <div>
      <h2>Every second <Highlight>saves</Highlight></h2>
      <p>
        Knockr's automated system reduces time spent on delivery exceptions by over 80%. With a reduction in labour, fuel, and opportunity costs, the benefits of an automated workflow add up fast.
      </p>
      </div>
    </Col>
    <Col className="vert-center" xs={{ span: 12, order: 'first' }} md={{ offset:1, span: 6, order: 'last' }}>
        <img className="info-img" src="/img/save-seconds.png"/>
    </Col>
  </Row>
  <Row className="content-block">
    <Col sm={12} md={6} className="vert-center">
        <img className="info-img" src="/img/shockingly-simple.png"/>
    </Col>
    <Col className="info-block vert-center" sm={12} md={5}>
      <div>
        <h2><Highlight>Shockingly</Highlight> simple</h2>
        <p>Using the combined technological might of smart software and pads of paper, Knockr enables a decision-free workflow for delivery drivers with no opportunity for errors.</p>
      </div>
    </Col>
  </Row>
  <Row className="content-block">
    <Col className="info-block  vert-center" xs={{ span: 12, order: 'last' }} md={{ span: 5, order: 'first' }}>
      <div>
        <h2><Highlight>Happy</Highlight> customers (and&nbsp;drivers)</h2>
        <p>Knockr understands the value of choice and the cost of a decision. Give customers more choice while relieving drivers of ambiguous decision-making.</p>
      </div>
    </Col>
    <Col className="vert-center" xs={{ span: 12, order: 'first' }} md={{ offset:1, span: 6, order: 'last' }}>
      <img className="info-img" src="/img/happy-customers.png"/>
    </Col>
  </Row>
</Container>
</div>;

const Workflow = () => <Container>
<Row className="content-block text-white">
  <Col xs={{ span: 12, order: 'last' }} md={{ span: 5, order: 'first' }} className="info-block">
    <h2>A <Highlight>decision-free</Highlight> workflow</h2>
    <p className="text-purple">When a parcel can't be delivered, traditional 10+ step workflows take minutes of walking, writing, and potentially ambiguous decision-making on the part of the driver.</p>
    <p className="text-purple">With Knockr, this whole process takes 3 steps, about 10 seconds, and 0 decisions.</p>
    <Button variant="white" href="mailto:info@virtual.com">Learn more</Button>
  </Col>
  <Col className="mb-5" xs={{ span: 12, order: 'first' }} md={{ offset: 1, span: 6, order: 'last' }}>
      <div className="workflow-container">
        <img className="info-img lg" src="/img/workflow.png"/>
        <div className="workflow-list"><p>1</p><p><Highlight>Grab</Highlight> Knockr pad from pocket.</p></div>
        <div className="workflow-list"><p>2</p><p><Highlight>Scan</Highlight> Knockr sheet.</p></div>
        <div className="workflow-list"><p>3</p><p><Highlight>Stick</Highlight> Knockr to front door.</p></div>
        <p className="text-left">It's that easy.</p>
      </div>
  </Col>
</Row>
</Container>;

const Hero = () => <Container className="hero text-white">
<Row className="content-block">
  <Col className="info-block large">
    <h2>
      Delivery<br/>experience,<br/><Highlight top={true}>automated</Highlight>.
    </h2>
    <Col sm={12} md={10} lg={10} xl={10}>
      <p className="text-purple">Knockr streamlines the delivery process to control costs, reduce complexity, and improve customer experience.</p>
    </Col>
    <Button variant="white" href="mailto:info@virtual.com">Learn more</Button>
  </Col>
  <Col lg>
    <div className="text-center circle-container">
      <img className="circle" src="/img/hero.png" />
    </div>
  </Col>
</Row>
</Container>;

const Home = () => (
    <div id="home">
      <Hero />
      <ValuePropositions />
      <Workflow />
      <DeliveringPromise />
    </div>    
);

export default Home;
