import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/Footer";
import { Navigation } from "./components/Navigation";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Home />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
]);

const App = () => (
  <>
    <Navigation />
    <RouterProvider router={router} />
    <Footer />
  </>
);

export default App;
