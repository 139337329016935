import { useEffect, useState } from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import Logo from './Logo';

const NavigationSpacer = () => <div style={{height: '60px', background: 'transparent'}}></div>

export const Navigation = () => {
    const [top, set_top] = useState<boolean>(true);
    useEffect(() => {
      window.onscroll = () =>
        window.pageYOffset < 50 ? set_top(true) : set_top(false)
    });
    return <><Navbar fixed="top" bg="primary" expand="lg" className={top ? `` :`shadow`}>
      <Container>
        <Navbar.Brand><a className="text-white" href="/"><Logo /></a></Navbar.Brand>
        <Button variant="white" href="mailto:info@virtual.com">Learn more</Button>
      </Container>
    </Navbar><NavigationSpacer /></>
  }